.successMessage {
  h3 {
    font-weight: 700;
    text-align: center;
    margin-top: 40px;
  }
  p {
    font-weight: 700;
  }
}

.content {
  text-align: center;
  padding-bottom: 50px;
  padding-top: 15px;
}
.card {
  padding: 20px;
  margin-top: 40px;
  border-radius: 0.25rem;
  box-shadow: 0 0.18rem 0.31rem -0.05rem rgb(0 0 0 / 16%),
    0 0.37rem 0.625rem 0 rgb(0 0 0 / 12%),
    0 0.0625rem 1.125rem 0 rgb(0 0 0 / 10%);

  &__item {
    padding: 20px;
  }
  p {
    font-weight: 400;
  }
}

.beitragContainer {
  margin-top: 80px;
}

.header {
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
  background-color: #e3f0e6;
  border-bottom: thin solid lightgrey;
  box-shadow: 0 2px 4px rgb(51 51 51 / 20%);

  svg {
    width: 50px;
    height: 50px;
    margin-bottom: 30px;
  }

  p {
    font-family: 'Fedra Serif' !important;
    color: #8e0038;
    margin: 0 !important;
    text-align: center;
  }

  span {
    text-align: center;
    font-weight: 700;
    padding-top: 15px;
    padding-bottom: 35px;
  }
}
