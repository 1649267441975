@import '~@eg/elements/styles/breakpoints';

.header {
  font-weight: 700;
  font-style: normal;
  line-height: 120%;
  text-align: center;
  color: #333333;
}
.payment_confirmation {
  color: #333;
  letter-spacing: 0;
  box-sizing: border-box;
  margin-top: 1rem;
  padding-bottom: 3rem;
  padding-top: 1rem;
  border-radius: 0.25rem;
  box-shadow: 0 0.18rem 0.31rem -0.05rem rgb(0 0 0 / 16%),
    0 0.37rem 0.625rem 0 rgb(0 0 0 / 12%),
    0 0.0625rem 1.125rem 0 rgb(0 0 0 / 10%);

  h3 {
    font-weight: 700;
    margin-bottom: 1rem;
  }
}

.payment_confirmation_box_with_lead {
  margin: 2rem 3rem 0 3rem;
}

.payment_confirmation_subContent {
  margin: 2rem 3rem 0 3rem;
}

.payment_confirmation_box_with_checkbox {
  border-radius: 0 0 0.25rem 0.25rem;
  padding: 1.5rem 3rem 2.5rem;
}

.checkbox {
  align-items: flex-start;
}

.payment_confirmation_subContent {
  text-align: left;
  font-weight: 500;
  h3 {
    font-weight: 700;
  }
  p {
    color: #333;
    text-align: center;
    line-height: 20px;
    margin-bottom: 24px;
  }
}
.checkbox_label {
  line-height: 24px;
  display: inline-block;
  cursor: pointer;
  padding: 0 1em 0 1.4em;
}
