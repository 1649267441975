@import '~@eg/elements/styles/breakpoints';

.welcomeContainer {
  h3 {
    text-align: left;
  }
}

.header {
  margin-left: 2.5%;
  margin-right: 2.5%;
  padding-top: 40px;
  font-weight: 700;
  line-height: 120%;
  text-align: center;
  color: #8e0038;
  hyphens: auto;

  p {
    font-family: 'Fedra Serif' !important;
  }
}
.title {
  padding-top: 5px;
  font-weight: 700;
  line-height: 133%;
  text-align: center;
  color: #333333;
}

.container {
  margin: auto;
  max-width: 1095px;
}

.peson_data {
  display: block;
}

.index {
  width: 25px;
  height: 25px;
  line-height: 25px;
  border-radius: 50%;
  margin-right: 14px;
  text-align: center;
  align-self: center;
  color: white;
  background-color: black;
  @media (max-width: ($breakpoint-m)) {
    width: 20px;
    height: 20px;
    line-height: 20px;
  }
}

.item {
  text-align: left;
  line-height: 0px;
  align-self: center;
  @media (max-width: ($breakpoint-m)) {
    line-height: 15px;
  }
}

.flex_col {
  display: flex;
  flex-direction: column;
}

.flex_row {
  display: flex;
  justify-content: left;
  height: 40px;
  line-height: 40px;
}

.iconMargin {
  margin-right: 25px;
}

.bottom_text {
  letter-spacing: 0.3px;
  line-height: 135%;
}
