@import '~@eg/elements/styles/breakpoints';

.tarifContainer {
  padding-left: 10px;

  p.asterisk {
    span {
      padding-right: 10px;
    }
  }
}

.tarifSection {
  padding-top: 10px;
  @media (min-width: ($breakpoint-l)) {
    display: flex;
    align-items: center;

    p {
      align-self: flex-start;
      margin-right: 30px;
      min-width: 30%;
    }
  }
  &__values {
    margin: 0 0 1em;
  }
}

.leistungContainer {
  display: flex;
  p {
    width: 100%;
  }
  svg {
    min-width: 24px;
    min-height: 24px;
    margin-right: 15px;
  }
  p strong {
    font-weight: 700;
  }
}
