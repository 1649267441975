@import '~@eg/elements/styles/breakpoints';

.angaben {
  &__list {
    list-style-type: none;
    padding-left: 10px;
  }
  & > div:first-of-type {
    border-top: 1px solid #e1e1e1;
  }
  &__heading {
    text-align: center;
    padding-top: 25px;
  }
  &__content {
    padding-left: 10px;

    @media (min-width: ($breakpoint-l)) {
      display: flex;
      align-items: center;

      p {
        align-self: flex-start;
        margin-top: 0;
        margin-right: 30px;
        min-width: 30%;
      }
    }
  }
  button {
    font-weight: 700 !important;
  }
}

.tarifSection {
  padding-top: 10px;
  @media (min-width: ($breakpoint-l)) {
    display: flex;
    align-items: center;

    p {
      align-self: flex-start;
      margin-right: 30px;
      min-width: 30%;
    }
  }
  &__values {
    margin: 0 0 1em;
  }
}
