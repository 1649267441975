@import '~@eg/elements/styles/breakpoints';

.header {
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-align: center;
  color: #333333;
  margin-top: 50px !important;
}
.subheader {
  margin-top: 0px;
  align-items: center;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  color: #333333;
  & p {
    width: 100%;
  }
}
.infoIcon {
  width: 20px;
  @media (max-width: ($breakpoint-m)) {
    width: 16px;
  }
}
.form {
  max-width: 768px;
  padding-top: 20px;
  padding-bottom: 1px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: ($breakpoint-m)) {
    margin-left: 0;
    margin-right: 0;
  }
}
.info {
  margin-top: 50px;
  margin-bottom: 0px;
}
.policyHolder {
  margin-top: 0px;
  margin-bottom: 12px;
}
.bottom_info {
  margin-left: 0;
  max-width: 900px;
  margin-top: 0;
  letter-spacing: 0;
  text-align: left;
  line-height: 150%;

  :nth-child(1) {
    font-weight: 700;
  }

  :nth-child(3) {
    margin-top: 40px;
  }
}
.bottom_info_icon {
  display: flex;
  font-weight: 700;
  align-items: center;
  line-height: 0px;
  :nth-last-child(1) {
    align-items: center;
  }
}
.bottom_info_bold {
  font-weight: 700;
}
.validation {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2.5rem;
  .p {
    text-align: left;
    padding: 2px 2px 2px 1rem;
    line-height: 20px;
  }
  .icon {
    align-self: baseline;
    margin-top: 13px;
    flex-shrink: 0;
  }
}
.input {
  width: 100%;
  & label {
    margin-top: 3px;
  }
}
div label {
  margin-top: 7px;
}
.input_container {
  width: '100%';
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.input_responsive_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ($breakpoint-m)) {
    align-items: flex-start;
    flex-direction: column;
  }
}
