.container {
  padding-left: 10px;

  b {
    font-weight: 700;
  }

  h3 {
    font-weight: 700;
    margin-bottom: 2rem;
  }
}
