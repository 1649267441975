@import '~@eg/elements/styles/breakpoints';

.idd {
  color: #333;
  letter-spacing: 0;
  line-height: 1.5rem;
  box-sizing: border-box;
  margin: 3rem auto 2.875rem auto;
  padding-top: 2rem;
  border-radius: 8px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);

  &__box-with-lead {
    margin: 0rem 2rem 2rem 2rem;
  }

  &__box-with-checkbox {
    border-radius: 0 0 0.25rem 0.25rem;
    padding: 1.5rem 2rem 2rem;

    &.--checked {
      background-color: #efe7ed;
    }
  }

  &__subContent {
    margin: 2rem auto 0 auto;
    text-align: center;

    h3 {
      font-weight: 700;
    }

    button {
      width: 312px;
      height: 48px;

      font-size: 16px;
      line-height: 24px;

      .idd__submit--small {
        font-size: 12px;
        line-height: 18px;
      }

      @media (min-width: ($breakpoint-l)) {
        width: 272px;
        height: 48px;
      }
    }

    p {
      color: #333;
      text-align: center;
      line-height: 20px;
      margin-bottom: 24px;
    }

    .idd__DownloadButton {
      .IddButton__div {
        font-weight: 700;
      }
    }

    .idd__SubmitButton {
      .ee_button__wrapper {
        padding: 0 !important;
      }
    }
  }
}

.bold {
  font-weight: 700;
}

.ee_checkbox {
  align-items: flex-start;
}

.checkbox-label {
  line-height: 24px;
  display: inline-block;
  cursor: pointer;
}

.ee_checkbox__box.ee_checkbox__box--regular.ee_checkbox__box--center {
  align-self: flex-start;
}

.ee_control-with-hint__hint {
  padding-left: 0 !important;
}

.header {
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-align: center;
  color: #333333;
  margin-top: 4rem !important;
}
