@import '~@eg/elements/styles/ergo-one/colors';
@import '~@eg/elements/styles/breakpoints';

.relative_container {
  position: relative;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  @media (max-width: ($breakpoint-m)) {
    justify-content: center;
  }
}

.headerContentWrap {
  display: flex;
  flex-direction: row;
  @media (max-width: ($breakpoint-m)) {
    flex-direction: column;
    align-items: center;
  }
}

.price_header {
  background: #e2f6fa;
  position: sticky;
  width: 100%;
  left: 0px;
  right: 0px;
  z-index: 499;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border-bottom: thin solid lightgrey;
  box-shadow: 0 2px 4px rgb(51 51 51 / 20%);
  @media (max-width: ($breakpoint-m)) {
    flex-direction: column;
    align-items: center;
  }
}

.description {
  @media (max-width: ($breakpoint-m)) {
    text-align: center;
  }
}

.label {
  font-weight: 700;
}

.description {
  margin-top: 0.4rem;
  font-size: 14px;
}

.price_field_container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-items: right;
  text-align: right;
}
.label_field_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  width: 100%;
  @media (max-width: ($breakpoint-m)) {
    align-items: center;
    height: 65px;
  }
}

.price_field {
  font-weight: 500;
  display: flex;
  white-space: nowrap;
  align-items: center;
  @media (max-width: ($breakpoint-m)) {
    align-items: center;
  }

  span {
    font-size: 28px;
    font-weight: 600;
    @media (max-width: ($breakpoint-m)) {
      font-size: 18px;
    }
  }
}

.price_small_text {
  font-size: 12px;
  text-align: right;
  @media (max-width: ($breakpoint-m)) {
    text-align: right;
  }
  white-space: nowrap;
}

.price {
  margin-right: 0.3rem;
}

.icon {
  color: black;
}
